// extracted by mini-css-extract-plugin
export var appendix = "index-module--appendix--Yytha";
export var commonPricingComponentContainer = "index-module--commonPricingComponentContainer--eIvhs";
export var limitWidthOnLargeScreens = "index-module--limitWidthOnLargeScreens--Qdc9y";
export var link = "index-module--link--fpW--";
export var list_lower_alpha = "index-module--list_lower_alpha--NdKcK";
export var list_lower_disc = "index-module--list_lower_disc--wLVWe";
export var list_lower_roman = "index-module--list_lower_roman--v7rUA";
export var list_lower_solid = "index-module--list_lower_solid--4wrIw";
export var list_number = "index-module--list_number--s69Mo";
export var margins = "index-module--margins--lPeDr";
export var pageTitle = "index-module--pageTitle--FHMDL";
export var section_title = "index-module--section_title--C+KT0";
export var text = "index-module--text--G9WDf";
export var text_bold = "index-module--text_bold--GOKOn";
export var text_bold2 = "index-module--text_bold2--vX5j4";
export var text_bold_helper = "index-module--text_bold_helper--Y4TXz";
export var text_helper = "index-module--text_helper--d2g+4";
export var ul_list = "index-module--ul_list--9mHA5";
export var ul_title = "index-module--ul_title--lYuKL";
export var ul_title2 = "index-module--ul_title2--R07qN";
export var wrapper = "index-module--wrapper--iQU+6";